<template>
    <v-container>

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-calendar-edit</v-icon>
            Admin - Leave
        </v-card-title>

        <v-container>
            <template>
                <v-card>
                    <v-card-title>
                        <v-radio-group
                        v-model="queryBy"
                        v-on:change="filterTableLeaves()"
                        row
                        >
                        <v-radio
                            label="Leave Date"
                            value="leavedt"
                        ></v-radio>
                        <v-radio
                            label="Applied Date"
                            value="applieddt"
                        ></v-radio>
                        <v-radio
                            label="Staff Name"
                            value="staffname"
                        ></v-radio>
                        </v-radio-group>
                    <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-title v-if="queryBy != null">
                        <!-- <v-select
                        :items="filterList"
                        v-model="selectedItem"
                        label="Leave Date"
                        hide-details
                        outlined
                        dense
                        @change="filterTable()"
                        ></v-select> -->
                        <v-row>
                            <v-col
                                cols="12"
                                md="6"
                                v-if="queryBy != 'staffname'"
                            >
                                <v-menu
                                v-model="calendarFrom"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="valueFromDate"
                                        label="Start Date"
                                        prepend-icon="mdi-calendar"
                                        hide-details=""

                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="valueFromDate"
                                        v-on:change="filterTableLeaves()"
                                        @input="calendarFrom = false"
                                    >
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                                v-if="queryBy != 'staffname'"
                            >
                                <v-menu
                                v-model="calendarTo"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="valueToDate"
                                        label="End Date"
                                        prepend-icon="mdi-calendar"
                                        hide-details=""
                                        class="pl-5"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="valueToDate"
                                        v-on:change="filterTableLeaves()"
                                        @input="calendarTo = false"
                                        :min="valueFromDate"
                                    >
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                md="8"
                                v-else
                            >
                                <v-autocomplete
                                    v-model="employeeName"
                                    :items="staffName"
                                    item-text="name"
                                    item-value="idemp"
                                    label="Staff Name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    v-on:change="queryContract=null;filterTableLeaves()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                                v-if="queryBy == 'staffname' && queryEmploy == 'contract'"
                            >
                                <v-select
                                    v-model="queryContract"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :items="contractList"
                                    label="Contract List"
                                    class="pl-2"
                                    v-on:change="filterTableLeaves()"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                                v-if="queryBy == 'staffname' && queryEmploy == null"
                            >
                                <v-select
                                    v-model="queryPermanent"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :items="permanentList"
                                    label="Year"
                                    class="pl-2"
                                    v-on:change="filterTableLeaves()"
                                ></v-select>
                            </v-col>
                        </v-row>
                        
                        <v-spacer></v-spacer>

                        <span 
                            class="text-caption"
                            v-if="queryBy == 'staffname' && employeeName != ''"
                        
                        >
                            <v-icon small>mdi-beach</v-icon> Total Annual Leave: {{ leaveInfo.totalannualleaveused }} from {{ leaveInfo.totalannualleave }}
                            <br>
                            <v-icon small>mdi-pill</v-icon> Total Medical Leave: {{ leaveInfo.totalmedicalleaveused }} from {{ leaveInfo.totalmedicalleave }}
                        </span>

                        <span v-if="queryBy == 'staffname'">
                            
                        </span>

                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            outlined
                            dense
                            hide-details
                            v-if="queryBy != 'staffname'"
                        ></v-text-field>
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="filteredLeaves"
                        :search="search"
                        class="elevation-1"
                        :custom-sort="customSort"
                    >
                        <template v-slot:[`header.leavetype`]="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small v-if="leaveTypeFilter == ''">
                                        mdi-filter
                                    </v-icon>
                                    <v-icon small v-else>
                                        mdi-filter-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card style="width: 280px" class="pa-2">
                                <v-card-text class="pa-0">
                                    <v-select
                                    v-model="leaveTypeFilter"
                                    :items="leaveType"
                                    label="Leave Type"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    ></v-select>
                                </v-card-text>
                                <v-card-actions class="pa-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    @click="leaveTypeFilter = ''"
                                    small
                                    outlined
                                    color="primary"
                                    class="mt-2"
                                    >Clear</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-menu>
                        </template>

                        <template v-slot:[`header.dateapplied`]="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small v-if="dateAppliedFilter == ''">
                                        mdi-filter
                                    </v-icon>
                                    <v-icon small v-else>
                                        mdi-filter-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card style="width: 280px" class="pa-2">
                                <v-card-text class="pa-0">
                                    <v-text-field
                                    v-model="dateAppliedFilter"
                                    label="Date Submitted"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    ></v-text-field>
                                </v-card-text>
                                <v-card-actions class="pa-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    @click="dateAppliedFilter = ''"
                                    small
                                    outlined
                                    color="primary"
                                    class="mt-2"
                                    >Clear</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-menu>
                        </template>

                        <template v-slot:[`header.name`]="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small v-if="empNameFilter == ''">
                                        mdi-filter
                                    </v-icon>
                                    <v-icon small v-else>
                                        mdi-filter-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card style="width: 280px" class="pa-2">
                                <v-card-text class="pa-0">
                                    <v-text-field
                                        v-model="empNameFilter"
                                        label="Applicant Name"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-card-text>
                                <v-card-actions class="pa-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    @click="empNameFilter = ''"
                                    small
                                    outlined
                                    color="primary"
                                    class="mt-2"
                                    >Clear</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-menu>
                        </template>
                        
                        <template v-slot:[`header.companyname`]="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small v-if="companyNameFilter == ''">
                                        mdi-filter
                                    </v-icon>
                                    <v-icon small v-else>
                                        mdi-filter-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card style="width: 280px" class="pa-2">
                                <v-card-text class="pa-0">
                                    <v-select
                                    v-model="companyNameFilter"
                                    :items="companyName"
                                    label="Company Name"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    ></v-select>
                                </v-card-text>
                                <v-card-actions class="pa-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    @click="companyNameFilter = ''"
                                    small
                                    outlined
                                    color="primary"
                                    class="mt-2"
                                    >Clear</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-menu>
                        </template>

                        <template v-slot:[`header.startdate`]="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small v-if="startDateFilter == ''">
                                        mdi-filter
                                    </v-icon>
                                    <v-icon small v-else>
                                        mdi-filter-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card style="width: 280px" class="pa-2">
                                <v-card-text class="pa-0">
                                    <v-text-field
                                    v-model="startDateFilter"
                                    label="Start Date"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    ></v-text-field>
                                </v-card-text>
                                <v-card-actions class="pa-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    @click="startDateFilter = ''"
                                    small
                                    outlined
                                    color="primary"
                                    class="mt-2"
                                    >Clear</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-menu>
                        </template>

                        <template v-slot:[`header.enddatetime`]="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small v-if="endDateFilter == ''">
                                        mdi-filter
                                    </v-icon>
                                    <v-icon small v-else>
                                        mdi-filter-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card style="width: 280px" class="pa-2">
                                <v-card-text class="pa-0">
                                    <v-text-field
                                    v-model="endDateFilter"
                                    label="End Date"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    ></v-text-field>
                                </v-card-text>
                                <v-card-actions class="pa-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    @click="endDateFilter = ''"
                                    small
                                    outlined
                                    color="primary"
                                    class="mt-2"
                                    >Clear</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-menu>
                        </template>

                        <template v-slot:[`header.leavestatus`]="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small v-if="leaveStatusFilter == ''">
                                        mdi-filter
                                    </v-icon>
                                    <v-icon small v-else>
                                        mdi-filter-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card style="width: 280px" class="pa-2">
                                <v-card-text class="pa-0">
                                    <v-select
                                    v-model="leaveStatusFilter"
                                    :items="leaveStatus"
                                    label="Approval Status"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    ></v-select>
                                </v-card-text>
                                <v-card-actions class="pa-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    @click="leaveStatusFilter = ''"
                                    small
                                    outlined
                                    color="primary"
                                    class="mt-2"
                                    >Clear</v-btn>
                                </v-card-actions>                                
                            </v-card>
                            </v-menu>
                        </template>
                        
                        <!-- <template v-slot:[`item.dateapplied`]="{ item }">
                            <span>{{ convertDateTime(item.dateapplied) }}</span>
                        </template> -->

                        <!-- <template v-slot:[`item.startdate`]="{ item }">
                            <span>{{ convertDateTime(item.startdate) }}</span>
                        </template>

                        <template v-slot:[`item.enddatetime`]="{ item }">
                            <span>{{ convertDateTime(item.enddatetime) }}</span>
                        </template> -->

                        <template v-slot:[`item.leavestatus`]="{ item }">
                            <v-chip
                                :color="getColor(item.leavestatus)"
                                dark
                            >
                                {{ item.leavestatus }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.detail`]="props">
                            <template>
                                <v-icon dense class="primary--text" @click="viewLeave(props.item)">mdi-file-document</v-icon>
                            </template>
                        </template>

                        <template v-slot:top>
                            <v-dialog
                            v-model="detailsDialog"
                            max-width="750px"
                            scrollable
                            >
                                <v-card>
                                    <v-card-title class="justify-center title-bar-modul">
                                        <h4 class="text-capitalize">{{ detailsLeave.leavetype.toLowerCase() }} Form</h4>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-simple-table>
                                            <tbody>
                                                <tr>
                                                    <td class="font-weight-bold">Submission Date:</td>

                                                    <td>
                                                        {{ detailsLeave.dateapplied }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold">Applicant Name:</td>

                                                    <td>
                                                        {{ detailsLeave.name }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold">Leave Type:</td>

                                                    <td>
                                                        {{ detailsLeave.leavetype }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold">Start Date:</td>

                                                    <td>
                                                        {{ (detailsLeave.startdate) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold">End Date:</td>

                                                    <td>
                                                        {{ (detailsLeave.enddatetime) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold">No of Days Applied:</td>

                                                    <td>
                                                        {{ detailsLeave.countused }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold">Address During Leave:</td>

                                                    <td>
                                                        {{ detailsLeave.address }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold">Reason:</td>

                                                    <td>
                                                        {{ detailsLeave.reason }}
                                                    </td>
                                                </tr>
                                                <tr v-if="detailsLeave.leavetype != 'ANNUAL LEAVE'">
                                                    <td class="font-weight-bold">
                                                        {{ detailsLeave.leavetype == 'MEDICAL LEAVE' ? 'Medical Certificate:' : 'Supporting Document:'}}
                                                    </td>

                                                    <td>
                                                        <v-btn
                                                            class="ma-0"
                                                            color="primary"
                                                            dark
                                                            small
                                                            @click="viewMC(detailsLeave.fileupload)"
                                                            v-if="detailsLeave.fileupload != null"
                                                        >
                                                            <v-icon dark small>
                                                            mdi-file-document-outline
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            class="ma-0"
                                                            color="grey"
                                                            dark
                                                            small
                                                            @click="viewMC(detailsLeave.fileupload)"
                                                            v-else
                                                        >
                                                            <v-icon dark small>
                                                            mdi-file-document-outline
                                                            </v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold">Leave Status:</td>

                                                    <td>
                                                        <span v-if="detailsLeave.leavestatus == 'PENDING'" class="orange--text">
                                                            <strong>PENDING</strong>
                                                        </span>
                                                        <span v-else-if="detailsLeave.leavestatus == 'APPROVED'" class="green--text">
                                                            <strong>{{ detailsLeave.leavestatus }}</strong>
                                                        </span>
                                                        <span v-else-if="detailsLeave.leavestatus == 'REJECTED'" class="red--text">
                                                            <strong>{{ detailsLeave.leavestatus }}</strong>
                                                        </span>
                                                        <span v-else class="pink--text text--darken-2">
                                                            {{ detailsLeave.leavestatus }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>

                                        <v-divider></v-divider>

                                        <template>
                                            <v-container class="pa-2">
                                                <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-card min-height="200px" class="cardLeave">
                                                    <v-card-title class="font-weight-bold justify-center pa-2">
                                                        <h5>SUPERVISOR APPROVAL</h5>
                                                    </v-card-title>

                                                    <v-divider></v-divider>

                                                    <v-simple-table>
                                                        <tbody>
                                                            <!-- <tr>
                                                                <td class="font-weight-bold">Submission Date:</td>
                                                                <td class="align-end">
                                                                    {{ detailsLeave.dateapplied }}
                                                                </td>
                                                            </tr> -->

                                                            <tr>
                                                                <td class="font-weight-bold">Supervisor Name:</td>
                                                                <td class="align-end">
                                                                    {{ detailsLeave.supervisorname }}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td class="font-weight-bold">Supervisor Approval:</td>
                                                                <td class="align-end">
                                                                    <span 
                                                                    v-if="detailsLeave.supervisorapproved == '' || detailsLeave.supervisorapproved == null" 
                                                                    class="orange--text">
                                                                        <strong>PENDING</strong>
                                                                    </span>
                                                                    <span 
                                                                    v-else-if="detailsLeave.supervisorapproved == 'APPROVED'" 
                                                                    class="green--text">
                                                                        <strong>{{ detailsLeave.supervisorapproved }}</strong>
                                                                    </span>
                                                                    <span 
                                                                    v-else-if="detailsLeave.supervisorapproved == 'REJECTED'" 
                                                                    class="red--text">
                                                                        <strong>{{ detailsLeave.supervisorapproved }}</strong>
                                                                    </span>
                                                                    <span v-else class="pink--text text--darken-2">
                                                                        {{ detailsLeave.supervisorapproved }}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </v-simple-table>
                                                    </v-card>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-card min-height="200px" class="cardLeave">
                                                    <v-card-title class="font-weight-bold justify-center pa-2">
                                                        <h5>SUPERIOR APPROVAL</h5>
                                                    </v-card-title>

                                                    <v-divider></v-divider>

                                                    <v-simple-table>
                                                        <tbody>
                                                            <!-- <tr>
                                                                <td class="font-weight-bold">Submission Date:</td>
                                                                <td class="align-end">
                                                                    {{ detailsLeave.dateapplied }}
                                                                </td>
                                                            </tr> -->

                                                            <tr>
                                                                <td class="font-weight-bold">Superior Name:</td>
                                                                <td class="align-end">
                                                                    {{ detailsLeave.superiorname }}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td class="font-weight-bold">Superior Approval:</td>
                                                                <td class="align-end">
                                                                    <span 
                                                                    v-if="detailsLeave.superiorapproved == '' || detailsLeave.superiorapproved == null" 
                                                                    class="orange--text">
                                                                        <strong>PENDING</strong>
                                                                    </span>
                                                                    <span 
                                                                    v-else-if="detailsLeave.superiorapproved == 'APPROVED'" 
                                                                    class="green--text">
                                                                        <strong>{{ detailsLeave.superiorapproved }}</strong>
                                                                    </span>
                                                                    <span 
                                                                    v-else-if="detailsLeave.superiorapproved == 'REJECTED'" 
                                                                    class="red--text">
                                                                        <strong>{{ detailsLeave.superiorapproved }}</strong>
                                                                    </span>
                                                                    <span v-else class="pink--text text--darken-2">
                                                                        {{ detailsLeave.superiorapproved }}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </v-simple-table>
                                                    </v-card>
                                                </v-col>
                                                </v-row>
                                            </v-container>
                                        </template>

                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-btn
                                            color="red"
                                            dark
                                            @click="close(); cancelDialog = true"
                                            v-if="detailsLeave.leavestatus != 'REJECTED' && detailsLeave.leavestatus != 'CANCELLED'"
                                        >
                                            Cancel Leave
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="red"
                                            outlined
                                            @click="close"
                                        >
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>

                        
                    </v-data-table>
                </v-card>
                
            </template>
            
            <!-- Confirmation dialog -->
            <template>
                <v-row justify="center">
                    <v-dialog
                    v-model="cancelDialog"
                    persistent
                    max-width="400"
                    >
                    <v-card>
                        <v-card-title class="justify-center">
                        Are you sure to cancel this leave?
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-list class="transparent" dense>
                                <v-list-item>
                                    <v-list-item-title class="details-title font-weight-bold">Applicant Name:</v-list-item-title>

                                    <v-list-item-subtitle class=" overflow-visible">
                                        {{ detailsLeave.name }}
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title class="details-title font-weight-bold">Leave Type:</v-list-item-title>

                                    <v-list-item-subtitle class=" overflow-visible">
                                        {{ detailsLeave.leavetype }}
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title class="details-title font-weight-bold">Start Date:</v-list-item-title>

                                    <v-list-item-subtitle class=" overflow-visible">
                                        {{ (detailsLeave.startdate) }}
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title class="details-title font-weight-bold">End Date:</v-list-item-title>

                                    <v-list-item-subtitle class=" overflow-visible">
                                        {{ (detailsLeave.enddatetime) }}
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title class="details-title font-weight-bold">No of Days Applied:</v-list-item-title>

                                    <v-list-item-subtitle class=" overflow-visible">
                                        {{ detailsLeave.countused }}
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green"
                            outlined
                            @click="cancelDialog = false"
                        >
                            No
                        </v-btn>
                        <v-btn
                            color="red"
                            outlined
                            @click="cancelLeave"
                        >
                            Yes
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-row>
            </template>
        </v-container>

        <!-- Snackbar for update message -->
        <template>
            <div class="text-center">
                <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                :color="messagecolor"
                >
                {{ messagetext }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
                </v-snackbar>
            </div>
        </template>

    </v-container>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    data: () => ({
        search: '',
        filterList: ['Today', 'This Month', 'Next Month','Last Month','This Year'],
        selectedItem: 'Today',
        headers: [
            {
            text: 'Leave Type',
            align: 'start',
            value: 'leavetype',
            },
            { text: 'Date Submitted', value: 'dateapplied' },
            { text: 'Applicant Name', value: 'name' },
            { text: 'Company', value: 'companyname' },
            { text: 'Start Date', value: 'startdate' },
            { text: 'End Date', value: 'enddatetime' },
            { text: 'Approval', value: 'leavestatus' },
            { text: 'Remarks', value: 'remark' },
            { text: 'Details', value: 'detail', sortable: false, align:'center'},
        ],

        leaves: [],
        detailsDialog: false,
        cancelDialog: false,
        companyName: [],
        leaveType: [],
        leaveStatus: ['PENDING', 'APPROVED', 'REJECTED', 'CANCELLED'],
        staffName: [],
        employeeName: '',
        contractList: [],
        contractQueryList: [],
        queryContract: null,
        queryEmploy: null,
        a: null,
        leaveInfo: {
            totalannualleave : 0,
            totalannualleaveused : 0,
            totalmedicalleave : 0,
            totalmedicalleaveused : 0
        },

        permanentList: [],
        queryPermanent: null,
        

        detailsLeave: {
            idemp: '',
            name: '',
            dateapplied: '',
            leavetype: '',
            startdate: '',
            enddatetime: '',
            supervisorapproved: '',
            superiorapproved: '',
            supervisorid: '',
            superiorid: '',
            supervisorname: '',
            superiorname: '',
            countused: '',
            leavestatus: '',
            totalannualleave: '',
            annualleaveadjustment: '',
            annualleaveused: '',
            totalmedicalleave: '',
            medicalleaveused: '',
            address: '',
            reason: '',
            fileupload: '',
            idcompany: '',
            companyname: ''
        },

        companyNameFilter: '',
        dateAppliedFilter: '',
        empNameFilter: '',
        leaveTypeFilter: '',
        startDateFilter: '',
        endDateFilter: '',
        leaveStatusFilter: '',

        queryBy: 'leavedt',
        calendarFrom: false,
        calendarTo: false,
        valueFromDate : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        valueToDate : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        // Snackbar message 
        snackbar: false,
        timeout: 3000,
        messagecolor: '',
        messagetext: '',
    }),

    computed: {
        itemsWithIndex() {
            return this.leaveList.map(
                (leaveList, index) => ({
                ...leaveList,
                index: index + 1
                }))
        },
        filteredLeaves() {
      
            let conditions = [];
            
            if (this.leaveTypeFilter) {
                conditions.push(this.filterLeaveType);
            }
            if (this.companyNameFilter) {
                conditions.push(this.filterCompanyName);
            }
            if (this.dateAppliedFilter) {
                conditions.push(this.filterDateApplied);
            }
            if (this.empNameFilter) {
                conditions.push(this.filterEmpName);
            }
            if (this.startDateFilter) {
                conditions.push(this.filterStartDate);
            }
            if (this.endDateFilter) {
                conditions.push(this.filterEndDate);
            }
            if (this.leaveStatusFilter) {
                conditions.push(this.filterLeaveStatus);
            }
            
            if (conditions.length > 0) {
                return this.leaves.filter((leave) => {
                return conditions.every((condition) => {
                    return condition(leave);
                })
                })
            }
            
            return this.leaves;
        }
    },

    methods: {
        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);
            
            items.sort((a, b) => {
                if (index[0]=='dateapplied' || index[0]=='startdate' || index[0]=='enddatetime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                    if (!isDesc[0]) {
                        return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                    }
                    else {
                        return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                    }
                    }
                }
            });
            return items;
        },

        getAllLeave(){
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

            axios.get(process.env.VUE_APP_API_URL + 'cuti/all?startdt='+ date +'&enddt='+ date +'&self=0&type=' + this.queryBy, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.leaves = response.data;
            })
            .catch(error => {
                console.log(error);
            })
        },
        getColor (leavestatus) {
            if (leavestatus == 'REJECTED') return 'red'
            else if (leavestatus == 'PENDING') return 'orange'
            else if (leavestatus == 'APPROVED') return 'green'
            else return 'pink darker-2'
        },
        viewLeave(item){
            this.editedIndex = this.leaves.indexOf(item);
            this.detailsLeave = Object.assign({}, item);
            this.detailsDialog = true;
        },
        close () {
            this.detailsDialog = false
        },
        filterTable(){
            let today = new Date();

            let start_date;
            let end_date;

            if(this.selectedItem == 'This Month'){
                start_date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+'01';
                end_date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+'31';
            } else if (this.selectedItem == 'This Year'){
                start_date = today.getFullYear()+'-01-01';
                end_date = today.getFullYear()+'-12-31';
            } else if (this.selectedItem == 'Today'){
                start_date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                end_date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            } else if (this.selectedItem == 'Next Month'){
                start_date = today.getFullYear()+'-'+(today.getMonth()+2)+'-'+'01';
                end_date = today.getFullYear()+'-'+(today.getMonth()+2)+'-'+'31';
            } else if (this.selectedItem == 'Last Month') {
                if(today.getMonth() == 0){
                    start_date = today.getFullYear()-1+'-12-'+'01';
                    end_date = today.getFullYear()-1+'-12-31';
                } else {
                    start_date = today.getFullYear()+'-'+(today.getMonth())+'-'+'01';
                    end_date = today.getFullYear()+'-'+(today.getMonth())+'-'+'31';
                }
            }
            
            axios.get(process.env.VUE_APP_API_URL + 'cuti/all?startdt='+ start_date +'&enddt='+ end_date +'&self=0', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                for(let i = 0; i < response.data.length; i++){
                    response.data[i].dateapplied = this.convertDateTime(response.data[i].dateapplied);
                    response.data[i].startdate = this.convertDateTime(response.data[i].startdate);
                    response.data[i].enddatetime = this.convertDateTime(response.data[i].enddatetime);
                }

                this.leaves = response.data;
            })
            .catch(error => {
                console.log(error);
            })
        },
        filterTableLeaves(){

            let link, startdate, enddate;
            

            if (this.queryBy != 'staffname') {
                link = process.env.VUE_APP_API_URL + 'cuti/all?startdt='+ this.valueFromDate +'T00:00:00&enddt='+ this.valueToDate +'T23:59:59&self=0&type=' + this.queryBy; 
                this.employeeName = '';
            } else {

                this.employeeName == '' ? this.employeeName = 'TT' : '';

                for(let i = 0; i < this.staffName.length; i++){
                    if(this.employeeName == this.staffName[i].idemp) {
                        if(this.staffName[i].employmenttypename != 'PERMANENT'){
                            this.queryEmploy = 'contract';
                            if(this.queryContract == null) {
                                this.queryContract = this.staffName[i].startcontractdt.split('T')[0] + ' ~ ' + this.staffName[i].endcontractdt.split('T')[0]
                                startdate = this.staffName[i].startcontractdt.split('T')[0]
                                enddate = this.staffName[i].endcontractdt.split('T')[0]
                                this.contractList = []
                                this.getContractList(this.staffName[i].idemp)
                            } else {
                                startdate = this.queryContract.split(' ~ ')[0]
                                enddate = this.queryContract.split(' ~ ')[1]

                                for (let i = 0; i < this.contractQueryList.length; i++) {
                                    if (startdate == this.contractQueryList[i].startdatecontract.split('T')[0] && enddate == this.contractQueryList[i].enddatecontract.split('T')[0]) {
                                        this.leaveInfo.totalannualleave = this.contractQueryList[i].totalannualleave == null ? 0 : this.contractQueryList[i].totalannualleave
                                        this.leaveInfo.totalannualleaveused = this.contractQueryList[i].annualleaveused == null ? 0 : this.contractQueryList[i].annualleaveused
                                        this.leaveInfo.totalmedicalleave = this.contractQueryList[i].totalmedicalleave == null ? 0 : this.contractQueryList[i].totalmedicalleave 
                                        this.leaveInfo.totalmedicalleaveused = this.contractQueryList[i].medicalleaveused == null ? 0 : this.contractQueryList[i].medicalleaveused
                                    }
                                }
                            }
                            
                        } else {
                            this.queryEmploy = null
                            if(this.queryPermanent == null){
                                let currentDate = new Date();
                                let currentYear = currentDate.getFullYear();
                                let lastYear = parseInt(currentYear) - 1;
                                startdate = currentYear + '-01-01'
                                enddate = currentYear + '-12-31'
                                this.contractList = []
                                
                                this.queryPermanent = currentYear

                                this.permanentList.push(currentYear)
                                this.permanentList.push(lastYear)
                            } else {
                                startdate = this.queryPermanent + '-01-01'
                                enddate = this.queryPermanent + '-12-31'
                            }
                            
                        }
                    }
                }
                link = process.env.VUE_APP_API_URL + 'cuti/all?startdt=' + startdate + 'T00:00:00&enddt=' + enddate + 'T23:59:59&self=0&type=leavedt&staffid=' + this.employeeName
            }

            axios.get(link, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                
                for(let i = 0; i < response.data.length; i++){
                    response.data[i].dateapplied = this.convertDateTime(response.data[i].dateapplied);
                    response.data[i].startdate = this.convertDateTime(response.data[i].startdate);
                    response.data[i].enddatetime = this.convertDateTime(response.data[i].enddatetime);
                }

                this.leaves = response.data;
                if(response.data.length > 0 && this.queryEmploy != 'contract'){
                    this.leaveInfo.totalannualleave = response.data[0].totalannualleave
                    this.leaveInfo.totalannualleaveused = response.data[0].annualleaveused
                    this.leaveInfo.totalmedicalleave = response.data[0].totalmedicalleave
                    this.leaveInfo.totalmedicalleaveused = response.data[0].medicalleaveused
                } else if (this.queryEmploy != 'contract') {
                    this.leaveInfo.totalannualleave = 0
                    this.leaveInfo.totalannualleaveused = 0
                    this.leaveInfo.totalmedicalleave = 0
                    this.leaveInfo.totalmedicalleaveused = 0
                }
            })
            .catch(error => {
                console.log(error);
            })
        },
        getContractList(idemp){
            axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab1&id='+idemp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                
                for(let i = 0; i < response.data.employeeinfo.kontraklist.length; i++){
                    this.contractList.push(response.data.employeeinfo.kontraklist[i].startdatecontract.split('T')[0] + ' ~ ' + response.data.employeeinfo.kontraklist[i].enddatecontract.split('T')[0])

                }

                this.leaveInfo.totalannualleave = response.data.employeeinfo.kontraklist[0].totalannualleave == null ? 0 : response.data.employeeinfo.kontraklist[0].totalannualleave
                this.leaveInfo.totalannualleaveused = response.data.employeeinfo.kontraklist[0].annualleaveused == null ? 0 : response.data.employeeinfo.kontraklist[0].annualleaveused
                this.leaveInfo.totalmedicalleave = response.data.employeeinfo.kontraklist[0].totalmedicalleave == null ? 0 : response.data.employeeinfo.kontraklist[0].totalmedicalleave
                this.leaveInfo.totalmedicalleaveused = response.data.employeeinfo.kontraklist[0].medicalleaveused == null ? 0 : response.data.employeeinfo.kontraklist[0].medicalleaveused

                this.contractQueryList = response.data.employeeinfo.kontraklist;

            })
            .catch(error => {
                console.log(error);
            })
        },
        getDropdownList(){

            axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                const company = response.data.company;
                
                for(let i = 0; i < company.length; i++){
                    this.companyName.push(company[i].name);
                }

                const leavetype = response.data.leavetype;
                
                for(let i = 0; i < leavetype.length; i++){
                    this.leaveType.push(leavetype[i].name);
                }
            })
            .catch(error => {
                console.log(error);
            })
        },

        getStaffList(){

            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.staffName = response.data;
            })
            .catch(error => {
                console.log(error);
            })
        },
        filterLeaveType(item) {
            return item.leavetype.includes(this.leaveTypeFilter);
        },
        filterCompanyName(item) {
            if(item.companyname != null) {
                return item.companyname.toString().includes(this.companyNameFilter);
            }
        },
        filterLeaveStatus(item) {
            return item.leavestatus.includes(this.leaveStatusFilter);
        },
        filterEmpName(item) {
            return item.name.toLowerCase().includes(this.empNameFilter.toLowerCase());
        },
        filterDateApplied(item) {
            return item.dateapplied.toLowerCase().includes(this.dateAppliedFilter.toLowerCase());
        },
        filterStartDate(item) {
            return item.startdate.toLowerCase().includes(this.startDateFilter.toLowerCase());
        },
        filterEndDate(item) {
            return item.enddatetime.toLowerCase().includes(this.endDateFilter.toLowerCase());
        },
        viewMC(link){
            if(link != null && link != ''){
                window.open(
                    link,
                    '_blank'
                );
            } else {
                this.messagetext = 'The medical certificate is not available.'
                this.messagecolor = 'error'
                this.snackbar = true
            }
            
        },

        cancelLeave(){
            const payload = {
                idemp: this.detailsLeave.idemp,
                startdt: this.detailsLeave.startdate,
                enddt: this.detailsLeave.enddatetime,
                fileupload: '',
                cancel: true
            };


            this.cancelDialog = false;

            axios.put(process.env.VUE_APP_API_URL + 'cuti/editcuti', payload, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                console.log(response.data);
                this.cancelDialog = false;
                this.getAllLeave();
                bus.$emit('changeIt', 'loadNotify');         
            })
            .catch(error => {
                console.log(error);
            });
        },
    },

    mounted () {
        this.filterTableLeaves();
        this.getDropdownList();
        this.getStaffList();
    },
}

</script>

<style lang="scss">
@import '~scss/main';

.overflow-visible {
    white-space: normal !important;
}

.details-title {
    max-width: 200px;
}

</style>
